<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickAdd">新增</el-button>
      <el-button type="danger" v-if="sconForm.status !== 2" plain size="small" @click="clickDel">删除</el-button>
    </div>
    <el-table :data="sconForm.scon_fees_list" border @selection-change="handleSelectionChange" show-summary>
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="费用名">
        <template slot="header">
          <span style="color: red">费用名</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_fees_list.' + scope.$index + '.scon_fees_name'" :rules="{ required: true, message: ' ' }">
            <template slot="label">
              <span style="display: none">费用名</span>
            </template>
            <el-input v-model="sconForm.scon_fees_list[scope.$index].scon_fees_name" maxlength="100" show-word-limit placeholder="暂无费用名"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="费用类型">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_fees_list.' + scope.$index + '.expense_type'">
            <el-select v-model="sconForm.scon_fees_list[scope.$index].expense_type" placeholder="请选择" size="mini" filterable>
              <el-option
                v-for="item in [
                  { value: 0, label: '佣金(折扣)' },
                  { value: 1, label: '其他(模具费等)' }
                ]"
                :value="item.value"
                :key="item.value + item.label"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="sconForm.scon_type === 1 ? '金额(美元)' : '金额￥'" prop="scon_fees_usd">
        <template slot="header">
          <span style="color: red" v-if="sconForm.scon_type === 1">金额(美元)</span>
          <span style="color: red" v-if="sconForm.scon_type === 2">金额￥</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_fees_list.' + scope.$index + '.scon_fees_usd'" :rules="{ required: true, message: ' ' }">
            <template slot="label">
              <span style="display: none" v-if="sconForm.scon_type === 1">金额(美元)</span>
              <span style="display: none" v-if="sconForm.scon_type === 2">金额￥</span>
            </template>
            <el-input
              @change="fessPriceChange(scope.$index)"
              v-model="sconForm.scon_fees_list[scope.$index].scon_fees_usd"
              maxlength="13"
              @input="sconForm.scon_fees_list[scope.$index].scon_fees_usd = helper.keepTNum2(sconForm.scon_fees_list[scope.$index].scon_fees_usd)"
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="费用备注">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_fees_list.' + scope.$index + '.expe_remake'">
            <el-input v-model="sconForm.scon_fees_list[scope.$index].expe_remake" maxlength="30" show-word-limit placeholder="暂无备注"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'sconProdList',
  props: {
    sconForm: Object
  },
  data() {
    return {
      selectionsList: [],
      delList: []
    };
  },
  methods: {
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickAdd() {
      this.sconForm.scon_fees_list.push({
        scon_fees_name: null,
        scon_fees_usd: null,
        expe_remake: null
      });
    },
    clickDel() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectionsList.forEach((item, index) => {
            this.sconForm.scon_fees_list.forEach((item2, index2) => {
              if (item === item2) {
                if (item2.destroy_flag !== undefined) {
                  item2.destroy_flag = 1;
                  this.delList.push(item2);
                }
                this.sconForm.scon_fees_list.splice(index2, 1);
                this.fessPriceChange();
              }
            });
          });
        })
        .catch(err => console.error(err));
    },
    //计算总金额
    fessPriceChange(index) {
      if (index || index === 0) {
        this.sconForm.scon_fees_list[index].scon_fees_usd = this.helper.calcPrice2(this.sconForm.scon_fees_list[index].scon_fees_usd, 4, 100000000);
      }
      let sum = 0;
      this.sconForm.scon_fees_list.forEach(item => {
        sum += this.helper.calcPrice(item.scon_fees_usd) - 0;
      });
      this.$emit('feetPriceChange', sum);
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
