<template>
  <div class="sconPartList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport">导入成品</el-button>
      <el-button type="danger" plain size="small" @click="deleteProd">删除</el-button>
      <!--      <el-button type="primary" size="small" @click="copySconProd">复制</el-button>-->
      <!--      <el-button class="tb-btn" size="small" @click="moveUp">上移</el-button>-->
      <!--      <el-button class="tb-btn" size="small" @click="moveDown">下移</el-button>-->
      <el-button type="primary" size="small" @click="podrSync" v-if="$route.path.indexOf('add') === -1">同步成品</el-button>
      <el-tooltip effect="light" placement="top" content="长按单条数据1s可上下拖动换位置">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <DynamicUTable
      ref="SconProdList"
      :tableRowKey="'tran_part_list'"
      :form="sconForm"
      :tableData="tableData"
      :columns="sconProdProperties"
      :isShow="isShow"
      :rowMove="true"
      :needPagination="false"
      :needSearch="false"
      :show-summary="true"
      :summariesColumns="['prod_num', 'prod_total_price', 'prod_box_num', 'total_gross_weight', 'total_net_weight', 'total_volume']"
      @row-click="prodNoChaange"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:prod_no="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_no'">
          <el-input v-model="scope.row.prod_no" disabled maxlength="30" show-word-limit placeholder="暂无我司货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:new_prod_no="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.new_prod_no'">
          <el-input v-model="scope.row.new_prod_no" disabled maxlength="30" show-word-limit placeholder="暂无新我司货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_poststfix="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_poststfix'">
          <el-input v-model="scope.row.prod_poststfix" disabled maxlength="2" show-word-limit placeholder="暂无货号后缀"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_cust_no'">
          <el-input v-model="scope.row.prod_cust_no" maxlength="30" show-word-limit placeholder="暂无客户货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ilk="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_ilk'">
          <el-select v-model="scope.row.prod_ilk" placeholder="暂无产品类型" size="small" clearable filterable>
            <el-option v-for="item in ilkList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_unit'">
          <el-select filterable disabled v-model="scope.row.prod_unit" placeholder="请选择成品单位">
            <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param2" :value="item.param2"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_shipstat="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_shipstat'">
          <el-input v-if="scope.row.prod_shipstat === 0" disabled v-model="prodShipstatValue0" show-word-limit placeholder="暂无"></el-input>
          <el-input v-if="scope.row.prod_shipstat === 1" disabled v-model="prodShipstatValue1" show-word-limit placeholder="暂无"></el-input>
          <el-input v-if="scope.row.prod_shipstat === 2" disabled v-model="prodShipstatValue2" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_num="scope">
        <el-form-item
          label="成品数量"
          label-width="0"
          :prop="'scon_prod_list.' + scope.$index + '.prod_num'"
          :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
        >
          <template slot="label"><span style="display: none">成品数量</span></template>
          <div style="display: flex">
            <el-input
              @change="
                prodTotalPrice(scope.$index);
                $refs.SconProdList.$refs.multiTable.updateData();
              "
              v-model="scope.row.prod_num"
              @input="scope.row.prod_num = helper.keepTNum(scope.row.prod_num)"
              maxlength="10"
              show-word-limit
              placeholder="暂无成品数量"
            ></el-input>
            <!--            <div style="display: flex; flex-direction: column; justify-content: center">-->
            <!--              <el-tooltip effect="dark" content="复制" placement="top">-->
            <!--                <el-link-->
            <!--                  class="vg_cursor"-->
            <!--                  :underline="false"-->
            <!--                  icon="el-icon-document-copy"-->
            <!--                  @click="copyCell(`prod_num[copyDataFlag]${scope.row.prod_num}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row.prod_num}`)"-->
            <!--                ></el-link>-->
            <!--              </el-tooltip>-->
            <!--              <span style="height: 9px"></span>-->
            <!--              <el-tooltip effect="dark" content="粘贴" placement="right">-->
            <!--                <el-link class="vg_cursor" :underline="false" icon="el-icon-finished" @click="pasteCell('prod_num', scope.row, scope.$index)"></el-link>-->
            <!--              </el-tooltip>-->
            <!--            </div>-->
          </div>
        </el-form-item>
      </template>
      <template v-slot:prod_usd="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_usd'">
          <el-input
            @change="
              prodTotalPrice(scope.$index);
              $refs.SconProdList.$refs.multiTable.updateData();
            "
            v-model="scope.row.prod_usd"
            @input="scope.row.prod_usd = helper.keepTNum1(scope.row.prod_usd)"
            @blur="scope.row.prod_usd = helper.calcPrice(scope.row.prod_usd, 4, 10000)"
            maxlength="9"
            show-word-limit
            placeholder="暂无销售价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_total_price="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_total_price'">
          <el-input v-model="scope.row.prod_total_price" disabled show-word-limit placeholder="暂无金额"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_qpb="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_qpb'">
          <el-input
            v-model="scope.row.prod_qpb"
            @input="scope.row.prod_qpb = helper.keepTNum(scope.row.prod_qpb)"
            maxlength="10"
            show-word-limit
            placeholder="暂无内包装数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_qpc="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_qpc'">
          <el-input
            @change="
              prodBoxNum(scope.$index);
              $refs.SconProdList.$refs.multiTable.updateData();
            "
            v-model="scope.row.prod_qpc"
            @input="scope.row.prod_qpc = helper.keepTNum(scope.row.prod_qpc)"
            maxlength="10"
            show-word-limit
            placeholder="暂无外包装数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_unit="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_box_unit'">
          <el-select filterable v-model="scope.row.prod_box_unit" placeholder="请选择外包装单位">
            <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_box_num="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_box_num'">
          <el-input v-model="scope.row.prod_box_num" disabled show-word-limit placeholder="暂无箱数"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxl="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxl'">
          <el-input
            @change="totalVolumeChange(scope.$index)"
            v-model="scope.row.prod_boxl"
            maxlength="7"
            @input="val => (scope.row.prod_boxl = keep2Decimal(val))"
            show-word-limit
            placeholder="暂无长"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxw="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxw'">
          <el-input
            @change="totalVolumeChange(scope.$index)"
            v-model="scope.row.prod_boxw"
            maxlength="7"
            @input="val => (scope.row.prod_boxw = keep2Decimal(val))"
            show-word-limit
            placeholder="暂无宽"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxh="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxh'">
          <el-input
            @change="totalVolumeChange(scope.$index)"
            v-model="scope.row.prod_boxh"
            maxlength="7"
            @input="val => (scope.row.prod_boxh = keep2Decimal(val))"
            show-word-limit
            placeholder="暂无高"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_gweight="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_gweight'">
          <el-input
            @change="
              totalGrossWeightChange(scope.$index);
              $refs.SconProdList.$refs.multiTable.updateData();
            "
            v-model="scope.row.prod_gweight"
            maxlength="7"
            @blur="scope.row.prod_gweight = helper.calcPriceT(scope.row.prod_gweight, 2, 10000)"
            @input="scope.row.prod_gweight = helper.keepTNum2(scope.row.prod_gweight)"
            show-word-limit
            placeholder="暂无外箱毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:total_gross_weight="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_gross_weight'">
          <el-input v-model="scope.row.total_gross_weight" disabled show-word-limit placeholder="暂无总毛重"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_nweight="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_nweight'">
          <el-input
            @change="
              totalNetWeight(scope.$index);
              $refs.SconProdList.$refs.multiTable.updateData();
            "
            v-model="scope.row.prod_nweight"
            @blur="scope.row.prod_nweight = helper.calcPriceT(scope.row.prod_nweight, 2, 10000)"
            @input="scope.row.prod_nweight = helper.keepTNum2(scope.row.prod_nweight)"
            maxlength="7"
            show-word-limit
            placeholder="暂无总毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:total_net_weight="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_net_weight'">
          <el-input v-model="scope.row.total_net_weight" disabled show-word-limit placeholder="暂无总净重"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_vol="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_vol'">
          <el-input
            disabled
            @change="
              totalVolumeChange(scope.$index);
              $refs.SconProdList.$refs.multiTable.updateData();
            "
            v-model="scope.row.prod_vol"
            maxlength="9"
            show-word-limit
            placeholder="暂无外箱体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:total_volume="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_volume'">
          <el-input v-model="scope.row.total_volume" disabled show-word-limit placeholder="暂无总体积"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_bar="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_bar'">
          <el-input
            v-model="scope.row.prod_bar"
            @input="scope.row.prod_bar = helper.keepTNum(scope.row.prod_bar)"
            maxlength="20"
            show-word-limit
            placeholder="暂无产品条形码"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_inbar="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_inbar'">
          <el-input
            v-model="scope.row.prod_inbar"
            @input="scope.row.prod_inbar = helper.keepTNum(scope.row.prod_inbar)"
            maxlength="20"
            show-word-limit
            placeholder="暂无内包装条形码"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_outbar="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_outbar'">
          <el-input
            v-model="scope.row.prod_outbar"
            @input="scope.row.prod_outbar = helper.keepTNum(scope.row.prod_outbar)"
            maxlength="20"
            show-word-limit
            placeholder="暂无外箱条形码"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_usd_price="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_usd_price'">
          <el-input
            v-model="scope.row.prod_usd_price"
            maxlength="9"
            @input="val => (scope.row.prod_usd_price = keep4Decimal(val))"
            show-word-limit
            placeholder="暂无美金报价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_stff_id="scope">
        <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.scon_stff_id'">
          <el-select filterable v-model="scope.row.scon_stff_id" placeholder="暂无对应外销" size="small" clearable @change="updatecustStffName(scope.$index)">
            <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-dialog title="成品信息" :visible.sync="dialogVisible" width="70%">
      <ProdImport @childConfirm="childConfirm" @childChanel="childChanel"></ProdImport>
    </el-dialog>
  </div>
</template>

<script>
import { get, postNoCatch } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { optnAPI } from '@api/modules/optn';
import ProdImport from './ProdImport.vue';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { keep2Decimal, keep3Decimal, keep4Decimal } from '@assets/js/regExUtil';
import { sconAPI } from '@api/modules/scon';
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { sconProdProperties } from '@/views/SalesManagement/SconManage/scon';
import helper from '@assets/js/helper';

export default {
  name: 'sconPartList',
  mixins: [myMixin],
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: true
    },
    addFlagId: {
      type: Number,
      required: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    prodUnitList: {
      type: Array,
      required: true
    },
    stffUserList: {
      type: Array,
      required: true
    },
    catDelList: {
      type: Array
    }
  },
  components: {
    DynamicUTable,
    ProdImport,
    textEnlargement
  },
  computed: {
    helper() {
      return helper;
    },
    tableData() {
      return this.sconForm.scon_prod_list;
    }
  },
  data() {
    return {
      sconProdProperties: sconProdProperties,
      dialogVisible: false,
      selectionsList: [],
      getIndex: '',
      prodShipstatValue0: '未出运',
      prodShipstatValue1: '已出运',
      prodShipstatValue2: '部分出运',
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      ilkList: [
        { id: 0, name: '新款' },
        { id: 1, name: '修改' },
        { id: 2, name: '翻单' }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    'sconForm.status'(newValue, oldValue) {
      if (newValue === 2) {
        this.sconProdProperties.forEach(item => {
          if (['成品数量', '销售价', '内包装数量', '外包装数量'].includes(item.label)) {
            item.subItem.disabled = true;
          }
        });
        this.$refs.SconProdList.refreshColumn();
      }
    },
    'sconForm.scon_type'(newValue, oldValue) {
      let temp = newValue === 2;
      this.sconProdProperties.forEach(item => {
        if (item.label === '开票品名') {
          item.visible = temp;
        } else if (item.prop === 'prod_total_price') {
          item.label = `金额${temp ? '￥' : '$'}`;
        }
      });
      this.$refs.SconProdList.refreshColumn();
    }
  },
  mounted() {},
  methods: {
    keep4Decimal,
    keep3Decimal,
    keep2Decimal,
    initData() {},
    //更改外壳显示
    updatecustStffName(index) {
      this.sconForm.scon_prod_list[index].cust_stff_name = this.stffUserList.filter(
        ({ stff_id }) => stff_id === this.sconForm.scon_prod_list[index].scon_stff_id
      )[0].stff_name;
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        if (['prod_box_num', 'total_gross_weight', 'total_net_weight', 'total_volume', 'prod_num', 'prod_total_price'].includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(4);
        }
      });
      return sums;
    },
    // 获取单位
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = Array.from(res.data.data, ({ stff_id, stff_name }) => {
              return { stff_id: stff_id, stff_name: stff_name };
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      this.dialogVisible = true;
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.sconForm.scon_prod_list.every(item1 => item.prod_id !== item1.prod_id));
      let temp = [];
      let temp1 = [];
      let temp2 = [];
      let idNum = this.addFlagId;
      for (let i = 0; i < val.length; i++) {
        val[i].prod_num = null;
        val[i].prod_total_price = null;
        // val[i].prod_box_unit = 'CTNS';
        val[i].prod_box_num = null;
        val[i].total_gross_weight = null;
        val[i].total_net_weight = null;
        val[i].total_volume = null;
        val[i].prod_shipstat = 0;
        val[i].prod_usd = this.helper.haveFour(val[i].prod_usd);
        val[i].scon_prod_id = idNum;
        val[i].cust_stff_id = val[i].scon_stff_id;
        val[i].scon_stff_id = this.sconForm.cust_stff_id;
        let filter = this.stffUserList.filter(({ stff_id }) => stff_id === this.sconForm.cust_stff_id);
        if (filter.length > 0) {
          val[i].cust_stff_name = filter[0].stff_name;
        } else {
          val[i].cust_stff_name = '';
        }
        // if (this.sconForm.cust_stff_id) {
        //   val[i].cust_stff_id = this.sconForm.cust_stff_id;
        // }
        if (val[i].prod_part_list) {
          let list = JSON.parse(JSON.stringify(val[i].prod_part_list));
          for (let j = 0; j < list.length; j++) {
            list[j].scon_prod_id = idNum;
          }
          temp = temp.concat(list);
        }
        if (val[i].prod_mtrb_list) {
          let list1 = JSON.parse(JSON.stringify(val[i].prod_mtrb_list));
          for (let j = 0; j < list1.length; j++) {
            list1[j].scon_prod_id = idNum;
          }
          temp1 = temp1.concat(list1);
        }
        if (val[i].prod_catnip_list) {
          let list2 = JSON.parse(JSON.stringify(val[i].prod_catnip_list));
          for (let j = 0; j < list2.length; j++) {
            list2[j].scon_prod_id = idNum;
          }
          temp2 = temp2.concat(list2);
        }
        idNum--;
      }
      for (let i = 0; i < temp.length; i++) {
        temp[i].scon_part_num = null;
        temp[i].prod_num = 0;
        temp[i].scon_part_applied = null;
        temp[i].scon_part_apply = null;
        temp[i].surplus_price = null;
        if (temp[i].prod_price) {
          temp[i].prod_price = this.helper.haveFour(temp[i].prod_price);
        } else {
          temp[i].prod_price = '0.0000';
        }
      }
      for (let i = 0; i < temp1.length; i++) {
        temp1[i].scon_mtrb_num = null;
        temp1[i].scon_mtrb_applied = null;
        temp1[i].prod_num = 0;
        temp1[i].scon_mtrb_apply = null;
        temp1[i].mtrb_total_price = null;
        temp1[i].prod_mtrb_price = this.helper.haveFour(temp1[i].prod_mtrb_price);
      }
      this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.concat(val);
      this.sconForm.scon_part_list = this.sconForm.scon_part_list.concat(temp);
      this.sconForm.scon_mtrb_list = this.sconForm.scon_mtrb_list.concat(temp1);
      this.sconForm.scon_catnip_list = this.sconForm.scon_catnip_list.concat(temp2);
      this.$refs.SconProdList.reload(this.sconForm.scon_prod_list);
      this.getIndex = 0;
      if (this.sconForm.scon_prod_list[0].scon_prod_id > 0) this.$emit('confirmProdIdCopy', this.sconForm.scon_prod_list[0].scon_prod_id);
      else this.$emit('confirmProdId', this.sconForm.scon_prod_list[0].prod_id);
      this.$emit('addFlagIdChange', idNum);
      this.dialogVisible = false;
      //重新计算排序字段
      this.sconProdSort();
    },
    // 删除
    deleteProd() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      let temp = [],
        temp1 = [],
        temp2 = [],
        temp3 = [],
        statusList = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        temp.push(this.selectionsList[i].prod_id);
      }

      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < this.sconForm.scon_part_list.length; j++) {
          if (this.sconForm.scon_part_list[j].prod_id === temp[i]) {
            temp1.push(j);
            if (this.sconForm.scon_part_list[j].create_status !== 0 && this.sconForm.scon_part_list[j].create_status) statusList.push(j);
          }
        }
        for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
          if (this.sconForm.scon_mtrb_list[j].prod_id === temp[i]) {
            temp2.push(j);
            if (this.sconForm.scon_mtrb_list[j].modr_status !== 0 && this.sconForm.scon_mtrb_list[j].modr_status) statusList.push(j);
          }
        }
        for (let j = 0; j < this.sconForm.scon_catnip_list.length; j++) {
          if (this.sconForm.scon_catnip_list[j].prod_id === temp[i]) {
            temp3.push(j);
          }
        }
      }
      if (statusList.length > 0) {
        return this.$message.warning('该商品部件或材料已生成采购合同，不可被删除，若要删除，请将生成的采购合同数量改为0!');
      }
      this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.filter(item => this.selectionsList.every(item1 => item.prod_id !== item1.prod_id));
      temp1 = temp1.sort((a, b) => {
        return b - a;
      });
      for (let j of temp1) {
        this.sconForm.scon_part_list.splice(j, 1);
      }
      temp2 = temp2.sort((a, b) => {
        return b - a;
      });
      for (let j of temp2) {
        this.sconForm.scon_mtrb_list.splice(j, 1);
      }
      temp3 = temp3.sort((a, b) => {
        return b - a;
      });
      for (let j of temp3) {
        this.sconForm.scon_catnip_list.splice(j, 1);
      }
      if (this.sconForm.scon_prod_list.length > 0) {
        this.getIndex = 0;
        if (this.sconForm.scon_prod_list[0].scon_prod_id > 0) {
          this.$emit('confirmProdIdCopy', this.sconForm.scon_prod_list[0].scon_prod_id);
        } else {
          this.$emit('confirmProdId', this.sconForm.scon_prod_list[0].prod_id);
        }
      }
      this.$refs.SconProdList.reload(this.sconForm.scon_prod_list);
    },
    // 单击每一行更换数据
    prodNoChaange(val, event, column) {
      this.getIndex = val.index;
      if (val.scon_prod_id > 0) this.$emit('confirmProdIdCopy', val.scon_prod_id);
      else this.$emit('confirmProdId', val.prod_id);
      this.$emit('prodCurrentIndex', val.index);
    },
    selectedstyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          'background-color': '#e1e1e1'
        };
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 计算金额
    prodTotalPrice(ind) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[ind].prod_usd);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[ind].prod_num).toNumber();
      this.sconForm.scon_prod_list[ind].prod_total_price = this.helper.haveFour(calcNum);
      this.prodBoxNum(ind);
      let prodId = this.sconForm.scon_prod_list[ind].prod_id;
      for (let i = 0; i < this.sconForm.scon_part_list.length; i++) {
        if (prodId === this.sconForm.scon_part_list[i].prod_id) {
          this.sconForm.scon_part_list[i].prod_num = this.sconForm.scon_prod_list[ind].prod_num;
          // this.sconForm.scon_part_list[i].scon_part_num = parseInt(
          //   (Number(this.sconForm.scon_part_list[i].prod_use) * 100000 * Number(this.sconForm.scon_part_list[i].prod_num)) / 100000
          // );
          //计算预计数量
          this.sconForm.scon_part_list[i].scon_part_num = new BigNumber(
            (Number(this.sconForm.scon_part_list[i].prod_use) * 100000 * Number(this.sconForm.scon_part_list[i].prod_num)) / 100000
          ).toFixed(2, 0);
          //计算实际数量
          this.sconForm.scon_part_list[i].scon_part_num_reality = this.sconForm.scon_part_list[i].scon_part_num;
          //计算需申购数量
          this.sconForm.scon_part_list[i].scon_part_apply = new BigNumber(
            this.sconForm.scon_part_list[i].scon_part_num_reality - this.sconForm.scon_part_list[i].scon_part_applied
          ).toFixed(2, 0);
          this.sconForm.scon_part_list[i].total_price = this.helper.haveFour(
            (Number(this.sconForm.scon_part_list[i].scon_part_num_reality) * 100000 * Number(this.sconForm.scon_part_list[i].prod_price)) / 100000
          );
          this.sconForm.scon_part_list[i].surplus_price =
            (Number(this.sconForm.scon_part_list[i].scon_part_num) * 100000 -
              Number(this.sconForm.scon_part_list[i].scon_part_applied) * 100000 -
              Number(this.sconForm.scon_part_list[i].scon_part_apply) * 100000) /
            100000;
        }
      }
      for (let i = 0; i < this.sconForm.scon_mtrb_list.length; i++) {
        if (prodId === this.sconForm.scon_mtrb_list[i].prod_id) {
          // -1 更新所有
          this.$emit('changeMtrbList', -1);
        }
      }
      //计算猫草数量
      for (let i = 0; i < this.sconForm.scon_catnip_list.length; i++) {
        if (prodId === this.sconForm.scon_catnip_list[i].prod_id) {
          let catnip_dosage = this.sconForm.scon_catnip_list[i].catnip_dosage ? this.sconForm.scon_catnip_list[i].catnip_dosage : 0;
          let prod_num = this.sconForm.scon_prod_list[ind].prod_num ? this.sconForm.scon_prod_list[ind].prod_num : 0;
          this.sconForm.scon_catnip_list[i].catnip_num = new BigNumber(Number(prod_num)).times(Number(catnip_dosage)).toFixed(2, 0); // 向上取整
        }
      }
    },
    // 上移
    moveUp() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
        this.$refs.SconProdList.clearSelection();
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
        this.$refs.SconProdList.clearSelection();
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.index === 0) {
          this.$message.warning('已经是第一条');
          this.$refs.SconProdList.clearSelection();
        } else {
          let ind = temp.index;
          this.sconForm.scon_prod_list.splice(ind, 1);
          this.sconForm.scon_prod_list.splice(ind - 1, 0, temp);
          this.$refs.SconProdList.clearSelection();
          this.$refs.SconProdList.$refs.multiTable.toggleRowSelection([temp]);
        }
      }
      //重新计算排序字段
      this.sconProdSort();
    },
    // 下移
    moveDown() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
        this.$refs.SconProdList.clearSelection();
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
        this.$refs.SconProdList.clearSelection();
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.index === this.sconForm.scon_prod_list.length - 1) {
          this.$message.warning('已经是最后一条');
          this.$refs.SconProdList.clearSelection();
        } else {
          let ind = temp.index;
          this.sconForm.scon_prod_list.splice(ind, 1);
          this.sconForm.scon_prod_list.splice(ind + 1, 0, temp);
          this.$refs.SconProdList.clearSelection();
          this.$refs.SconProdList.$refs.multiTable.toggleRowSelection([temp]);
        }
      }
      //重新计算排序字段
      this.sconProdSort();
    },
    //重新计算排序字段
    sconProdSort() {
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        this.sconForm.scon_prod_list[i].prod_sort = i + 1;
      }
    },
    //成品复制按钮
    copySconProd() {
      if (this.selectionsList.length === 0) return this.$message.warning('请至少选择一条数据!');
      let copyProdList = cloneDeep(this.selectionsList);
      copyProdList.forEach(item => {
        item.scon_prod_id = null;
        item.prod_id = 0;
        delete item._XID;
      });
      if (this.sconForm.scon_prod_list[0].scon_prod_id) {
        this.$emit('confirmProdIdCopy', this.sconForm.scon_prod_list[0].scon_prod_id);
      } else {
        this.$emit('confirmProdId', this.sconForm.scon_prod_list[0].prod_id);
      }
      this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.concat(copyProdList);
    },
    //销售成品子表同步成品按钮（从成品信息里从新获取数据）
    podrSync() {
      postNoCatch(sconAPI.getPodrSync, {
        scon_id: this.sconForm.scon_id,
        scon_prod_list: this.sconForm.scon_prod_list,
        scon_catnip_list: this.sconForm.scon_catnip_list
      })
        .then(({ data }) => {
          this.$set(this.sconForm, 'scon_prod_list', data.scon_prod_list);
          this.$set(this.sconForm, 'scon_catnip_list', data.scon_catnip_list);
          this.sconForm.scon_prod_list.forEach((item, index) => {
            this.prodBoxNum(index);
            this.prodTotalPrice(index);
          });
        })
        .catch(err => console.log(err));
      this.$emit('changeSyncAll');
    },
    // 计算总毛重
    totalGrossWeightChange(val) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[val].prod_gweight);
      if (!calcNum.isFinite()) this.sconForm.scon_prod_list[val].total_gross_weight = null;
      else this.sconForm.scon_prod_list[val].total_gross_weight = this.helper.reservedDigits(calcNum.toNumber());
    },
    // 计算总净重
    totalNetWeight(val) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[val].prod_nweight);
      if (!calcNum.isFinite()) this.sconForm.scon_prod_list[val].total_net_weight = null;
      else this.sconForm.scon_prod_list[val].total_net_weight = this.helper.reservedDigits(calcNum.toNumber());
    },
    // 总体积
    totalVolumeChange(val) {
      let prod_vol = new BigNumber(this.sconForm.scon_prod_list[val].prod_boxl)
        .times(this.sconForm.scon_prod_list[val].prod_boxw)
        .times(this.sconForm.scon_prod_list[val].prod_boxh)
        .div(1000000);
      if (!prod_vol.isFinite()) return;
      this.sconForm.scon_prod_list[val].prod_vol = prod_vol.toFixed(3);
      let temp = new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num).times(this.sconForm.scon_prod_list[val].prod_vol);
      if (!temp.isFinite()) this.$set(this.sconForm.scon_prod_list[val], 'total_volume', null);
      else
        this.$nextTick(() => {
          this.$set(this.sconForm.scon_prod_list[val], 'total_volume', temp.toFixed(3));
        });
    },
    // 计算箱数
    prodBoxNum(val) {
      if (Number(this.sconForm.scon_prod_list[val].prod_num) % Number(this.sconForm.scon_prod_list[val].prod_qpc) !== 0) {
        this.$message.warning('箱数未能整除，已向上取整');
      }
      let temp = new BigNumber(this.sconForm.scon_prod_list[val].prod_num).div(this.sconForm.scon_prod_list[val].prod_qpc);
      if (temp.isFinite()) {
        this.sconForm.scon_prod_list[val].prod_box_num = temp.toFixed(0, 0);
      } else {
        this.sconForm.scon_prod_list[val].prod_box_num = null;
      }
      this.totalGrossWeightChange(val);
      this.totalNetWeight(val);
      this.totalVolumeChange(val);
      this.$emit('sconProdPriceChange');
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.sconForm.scon_prod_list[val1].prod_espec;
      } else if (val === 1) {
        this.textEnlargementString.str = this.sconForm.scon_prod_list[val1].prod_ename;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.sconForm.scon_prod_list[this.textEnlargementNum].prod_espec = val;
      } else if (this.textEnlargementFlag === 1) {
        this.sconForm.scon_prod_list[this.textEnlargementNum].prod_ename = val;
      }
    },
    getRowKeys(row) {
      return row.scon_prod_id;
    },
    //判断内贸外销时字段名称显示
    changePriceName(type) {
      if (type === 'RMB') {
        this.sconProdProperties.find(({ prop }) => prop === 'prod_total_price').label = '金额￥';
      } else {
        this.sconProdProperties.find(({ prop }) => prop === 'prod_total_price').label = '金额(美元)';
      }
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    pasteCell(column, row, index) {
      let indNum = 0;
      navigator.clipboard
        .readText()
        .then(text => {
          let textSplit = text.split('[copyDataFlag]');
          if (textSplit.length > 1 && textSplit[0] === column) {
            if (Number(textSplit[2]) === index) return;
            let rangeIndexArr;
            if (Number(textSplit[2]) > index) rangeIndexArr = [index, Number(textSplit[2])];
            else rangeIndexArr = [Number(textSplit[2]), index];
            this.sconForm.scon_prod_list.forEach((item, tIndex) => {
              if (tIndex >= rangeIndexArr[0] && tIndex <= rangeIndexArr[1]) {
                indNum++;
                if (textSplit[3] === 'number') {
                  this.$set(item, column, Number(textSplit[1]));
                } else {
                  this.$set(item, column, textSplit[1]);
                }
                //计算粘贴的内容 第一条为复制内容
                if (indNum > 1) {
                  this.prodTotalPrice(tIndex);
                }
              }
            });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-table__body-wrapper {
  z-index: 2;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .el-form-item__label {
  display: none;
}
</style>
