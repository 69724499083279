<template>
  <el-form ref="sconFormRef" :model="sconForm" :rules="sconFormRules" label-width="120px" size="mini">
    <div class="sconAddMain">
      <div v-sticky class="vd_edhea" style="padding-top: 16px; background: white">
        <add-header :stateFlag="true" @cancel="cancel" @submit="submit('sconFormRef')"></add-header>
        <!--<div class="vd_export2" style="padding-top: 16px">-->
        <!--  <span style="margin-right: 2px">{{ sconForm.scon_type === 1 ? '外销' : '内贸' }}合同号:</span>-->

        <!--  <el-form-item :label="(sconForm.scon_type === 1 ? '外销' : '内贸') + '合同号'" prop="scon_no">-->
        <!--    <el-input-->
        <!--      v-model="sconForm.scon_no"-->
        <!--      maxlength="8"-->
        <!--      show-word-limit-->
        <!--      size="small"-->
        <!--      style="width: 150px"-->
        <!--      @input="val => (sconForm.scon_no = keepEngAndNumUp(val))"-->
        <!--    ></el-input>-->
        <!--  </el-form-item>-->
        <!--</div>-->
      </div>
      <el-row>
        <el-col :md="24" class="vg_mb_8">
          <el-form-item label="销售类型" prop="scon_type">
            <el-radio v-model="sconForm.scon_type" :label="1" @input="val => changeCurrency('USD')">外销</el-radio>
            <el-radio v-model="sconForm.scon_type" :label="2" @input="val => changeCurrency('RMB')">内贸</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_id">
            <el-select v-model="sconForm.cust_id" size="small" filterable placeholder="请选择客户" @change="val => clickSelectCust(val, 1)">
              <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
            <!--            <el-input v-model="sconForm.cust_abbr" disabled placeholder="请选择客户">-->
            <!--              <template slot="append">-->
            <!--                <el-link class="vg_pointer" @click="clickSelectCust(1)" type="primary">双击选择</el-link>-->
            <!--              </template>-->
            <!--            </el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="最终客户" prop="cust_bid">
            <el-select v-model="sconForm.cust_bid" size="small" filterable placeholder="请选择客户" @change="val => clickSelectCust(val, 2)">
              <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
            <!--            <el-input v-model="sconForm.cust_babbr" disabled placeholder="请选择最总客户">-->
            <!--              <template slot="append">-->
            <!--                <el-link class="vg_pointer" @click="clickSelectCust(2)" type="primary">双击选择</el-link>-->
            <!--              </template>-->
            <!--            </el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号" prop="scon_cust_no">
            <el-input
              v-model="sconForm.scon_cust_no"
              placeholder="请输入客户订单号"
              maxlength="20"
              @blur="val => changeCustNoIsRepeat(val.target.value)"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="合同日期" prop="scon_cndate">
            <el-date-picker v-model="sconForm.scon_cndate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售所属部门" prop="cust_dept_id">
            <el-select v-model="sconForm.cust_dept_id" filterable @change="custDeptChange">
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号备注" prop="scon_cust_no_remark">
            <el-input v-model="sconForm.scon_cust_no_remark" placeholder="请输入客户订单号备注" maxlength="20" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="贸易方式" prop="cust_tradeway">
            <el-select v-model="sconForm.cust_tradeway" placeholder="请选择贸易方式">
              <el-option v-for="item in tradewayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="装运日期" prop="scon_shdate">
            <el-date-picker value-format="timestamp" v-model="sconForm.scon_shdate" @change="val => chageShdate(val)" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销经办人" prop="cust_stff_id">
            <el-select v-model="sconForm.cust_stff_id" :disabled="!sconForm.cust_dept_id" filterable placeholder="请选择外销经办人" @change="changeCustStff">
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="付款方式" prop="cust_payway">
            <el-select v-model="sconForm.cust_payway" @change="val => updatePpdate(val)" placeholder="请选择付款方式">
              <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户账期" prop="cust_creday">
            <el-input
              v-model="sconForm.cust_creday"
              placeholder="请输入客户账期"
              maxlength="3"
              show-word-limit
              @input="sconForm.cust_creday = helper.pureNumber(sconForm.cust_creday)"
              @change="val => changeCreday(val)"
            >
              <!--@change="shdateChange2"-->
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="预计收款日期" prop="scon_ppdate">
            <el-date-picker v-model="sconForm.scon_ppdate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 1">
        <el-col :md="8">
          <el-form-item label="起运港" prop="scon_sport">
            <el-select v-model="sconForm.scon_sport" placeholder="请选择起运港" filterable>
              <el-option v-for="item in portOfShipmentList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-select v-model="sconForm.cust_dcountry" placeholder="请选择目的国" filterable>
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param2" :value="item.param2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-select v-model="sconForm.cust_dport" filterable placeholder="请选择目的港">
              <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="货款金额" prop="scon_prtotal">
            <el-input v-model="sconForm.scon_prtotal" disabled maxlength="13" placeholder="暂无货款金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用金额" prop="scon_fetotal">
            <el-input v-model="sconForm.scon_fetotal" disabled maxlength="13" placeholder="暂无费用金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="scon_usdrate">
            <el-input
              v-model="sconForm.scon_usdrate"
              :disabled="this.rateDisable"
              placeholder="请输入汇率"
              maxlength="9"
              show-word-limit
              @input="sconForm.scon_usdrate = helper.keepTNum1(sconForm.scon_usdrate)"
              @change="sconForm.scon_usdrate = new BigNumber(sconForm.scon_usdrate).toFixed(6)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="合同金额" prop="scon_total">
            <el-input v-model="sconForm.scon_total" disabled maxlength="13" placeholder="暂无合同金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交易币种" prop="cust_currency">
            <el-select v-model="sconForm.cust_currency" placeholder="请选择交易币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="人民币金额" prop="scon_rmbtotal">
            <el-input v-model="sconForm.scon_rmbtotal" disabled placeholder="暂无人民币金额" maxlength="13">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="运输方式" prop="cust_shipway">
            <el-select v-model="sconForm.cust_shipway" placeholder="请选择运输方式">
              <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="保险条款" prop="scon_insure">
            <el-input v-model="sconForm.scon_insure" placeholder="请输入保险条款" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="包装要求" prop="scon_pack">
            <el-input v-model="sconForm.scon_pack" placeholder="请输入包装要求" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8" v-if="isBussinessDept5">
          <el-form-item label="是否导入验货" prop="scon_inspection">
            <el-select v-model="sconForm.scon_inspection" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: 0, label: '否' },
                  { value: 1, label: '是' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="订单类型" prop="scon_category">
            <el-select v-model="sconForm.scon_category" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: '1', label: 'S(非常重要)' },
                  { value: '2', label: 'A(重要)' },
                  { value: '3', label: 'B(一般)' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="系列名" prop="scon_series_name">
            <el-input v-model="sconForm.scon_series_name" placeholder="请输入系列名/订单类别" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="价格条款" prop="cust_paylaw">
            <el-select v-model="sconForm.cust_paylaw" placeholder="请选择价格条款">
              <el-option v-for="item in custPaylwayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="订单类别" prop="scon_order_series">
            <el-input v-model="sconForm.scon_order_series" placeholder="请输入订单系列" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="目的地" prop="cust_bourm" v-if="sconForm.scon_type === 2">
            <el-input v-model="sconForm.cust_bourm" placeholder="请输入目的地" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 1">
        <el-col :md="24">
          <el-form-item label="货物总称" prop="scon_bulkname">
            <el-input v-model="sconForm.scon_bulkname" type="textarea" rows="3" placeholder="请输入货物总称" maxlength="255" show-word-limit clearable>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="交货及验收" prop="deli_and_check">
            <el-input v-model="sconForm.deli_and_check" type="textarea" rows="3" placeholder="请输入" maxlength="255" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="其他约定" prop="other_promi">
            <el-input v-model="sconForm.other_promi" type="textarea" rows="3" placeholder="请输入" maxlength="255" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="装运备注" prop="ship_remarks">
            <el-input v-model="sconForm.ship_remarks" type="textarea" rows="3" placeholder="请输入" maxlength="500" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="sconForm.scon_type === 2" class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="开票资料" prop="scon_inv_gen">
            <el-input v-model="sconForm.scon_inv_gen" clearable maxlength="255" placeholder="请输入" rows="3" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <SconProdList
            :showFlagNumIdCopy="showFlagNumIdCopy"
            :sconForm="sconForm"
            ref="SconProdList"
            :showFlagNumId="showFlagNumId"
            :prodUnitList="prodUnitList"
            :addFlagId="addFlagId"
            :stffUserList="stffUserList"
            :is-show="false"
            @confirmProdIdCopy="confirmProdIdCopy"
            @confirmProdId="confirmProdId"
            @addFlagIdChange="addFlagIdChange"
            @sconProdPriceChange="sconProdPriceChange"
            @prodCurrentIndex="changeTransmitIndex"
            @changeMtrbList="index => $refs.sconMtrbList.prodMtrbPeruseChange(index)"
          />
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="部件明细" name="first" :key="'first'">
              <SconPartList
                ref="SconPartList"
                :suppList="suppList"
                :prodUnitList="prodUnitList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :sconForm="sconForm"
                :is-show="false"
                :transmitIndex="transmitIndex"
                :showFlagNumId="showFlagNumId"
              />
            </el-tab-pane>
            <el-tab-pane label="材料明细" name="second" :key="'second'">
              <SconMtrbList
                ref="sconMtrbList"
                :suppList="suppList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :transmitIndex="transmitIndex"
                :sconForm="sconForm"
                :is-show="false"
                :showFlagNumId="showFlagNumId"
              />
            </el-tab-pane>
            <el-tab-pane label="猫草信息" name="third" :key="'third'">
              <SconCatnipList
                ref="SconCatnipList"
                :suppList="suppList"
                :prodUnitList="prodUnitList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :transmitIndex="transmitIndex"
                :sconForm="sconForm"
                :is-show="false"
                :showFlagNumId="showFlagNumId"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <el-tabs v-model="activeName2" type="border-card">
            <el-tab-pane label="费用明细" name="first" :key="'first'">
              <SconFeesList :sconForm="sconForm" @feetPriceChange="feetPriceChange" />
            </el-tab-pane>
            <el-tab-pane label="条款明细" name="second" :key="'second'">
              <SconClauInfo ref="SconClauInfo" :sconForm="sconForm" @sconClauChange="sconClauChange" @sconClauChange2="sconClauChange2" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>

      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser ref="userData" :isCookie="true"></inputUser>
        </el-col>
      </el-row>
      <el-dialog :visible.sync="dialogCustVisible" title="客户信息" width="70%">
        <ImportCust @importCustChange="importCustChange" />
      </el-dialog>
    </div>
  </el-form>
</template>

<script>
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import SconProdList from './Component/SconProdList.vue'; //成品明细
import SconPartList from './Component/SconPartList.vue'; //部件明细
import SconMtrbList from './Component/SconMtrbList.vue'; //材料明细
import SconFeesList from './Component/SconFeesList.vue'; //费用明细
import SconCatnipList from './Component/SconCatnipList.vue'; //猫草明细
import SconClauInfo from './Component/SconClauInfo'; //条款明细
import ImportCust from './Component/ImportCust.vue';

import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import { settAPI } from '@/api/modules/sett';
import { sconAPI } from '@/api/modules/scon';
import { deptAPI } from '@api/modules/department';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { getDept, getSupp } from '@api/public';
import { BigNumber } from 'bignumber.js';
import { custAPI } from '@api/modules/cust';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';
import { keepEngAndNumUp } from '@assets/js/regExUtil';

export default {
  name: 'sconAddMain',
  computed: {
    BigNumber() {
      return BigNumber;
    }
  },
  components: {
    addHeader,
    inputUser,
    SconProdList,
    SconPartList,
    SconMtrbList,
    SconFeesList,
    SconCatnipList,
    SconClauInfo,
    ImportCust
  },
  data() {
    return {
      transmitIndex: 0,
      custs: [],
      sconForm: {
        scon_type: 1,
        scon_no: null, //外销合同编号
        scon_shipstat: null, //出运状态
        scon_cust_no: null, //客户订单号
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cust_name: null, //客户全称
        cust_bid: null, //最终客户id
        cust_babbr: null, //最总选择客户简称
        scon_cndate: new Date(), //合同日期
        scon_shdate: null, //装运日期
        cust_stff_id: null, //外销经办人
        cust_tradeway: 'General Trade', //贸易方式
        cust_shipway: 'Sea', //运输方式
        cust_paylaw: null, //价格条款
        cust_payway: null, //付款方式
        cust_creday: null, //客户账期
        scon_ppdate: null, //预计收款日期
        scon_sport: 'SHANGHAI', //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        scon_prtotal: '0.0000', //货款金额
        scon_fetotal: '0.0000', //费用金额
        scon_total: '0.0000', //合同金额
        cust_currency: 'USD', //币种
        scon_usdrate: null, //美元汇率
        scon_rmbtotal: '0.0000', //本币金额 人民币
        scon_bulkname: 'PET PRODUCTS', //货物总称
        scon_insure: 'TO BE COVERED BY BUYER', //保险条款
        scon_pack: 'IN CARTONS', //包装要求
        scon_order_series: null, //订单系列
        scon_prod_list: [], //子表 商品明细
        scon_part_list: [], //子表 部件明细
        scon_catnip_list: [], //子表 猫草明细
        scon_mtrb_list: [], //子表 材料明细
        scon_fees_list: [], //子表 费用明细
        cust_dept_id: null, //外销所属部门 111111
        cptt_aid: null, //公司抬头id
        cptt_aname_en: null, //公司英文抬头
        cptt_bid: null, //收款抬头
        cust_shipmark: null, //唛头
        scon_remark: null, //备注
        clau_content: null, //条款内容
        inst_id: null, //货代id 机构
        inst_abbr: null, //机构名称
        inst_ename: null, //货代英文名称 机构
        cptt_bname_en: null, //收款抬头英文
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null //银行信息,
      },
      activeName: 'first',
      activeName2: 'first',
      dialogCustVisible: false,
      importCustType: null,
      stffUserList: [], //经办人
      tradewayList: [], //贸易方式
      custShipwayList: [], //运输方式
      custPaylwayList: [], //价格条款
      custPaywayList: [], //付款方式
      custDportList: [], //港
      portOfShipmentList: [
        { label: 'SHANGHAI', value: 'SHANGHAI' },
        { label: 'NINGBO', value: 'NINGBO' },
        { label: 'QINGDAO', value: 'QINGDAO' },
        { label: 'SHENZHEN', value: 'SHENZHEN' }
      ], //港
      custCountryList: [], //目的国
      custCurrencyList: [], //币种
      cptt_bank_list: [], //银行
      deptList: [],
      sconFormRules: {
        // scon_no: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_type: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_usdrate: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bourm: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_shdate: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_tradeway: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_shipway: [{ required: true, message: ' ', trigger: ['channge', 'blur'] }],
        cust_paylaw: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_payway: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_sport: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_dept_id: [{ required: true, message: ' ', trigger: 'blur' }],
        ship_remarks: [{ required: false, message: ' ', trigger: 'blur' }]
      },

      // 材料信息和部件信息公用，等会添加编辑
      showFlagNumId: 0,
      addFlagId: -1,
      showFlagNumIdCopy: 0,
      prodUnitList: [],
      suppList: [],
      sconTypeList: [
        { id: 1, label: '外贸' },
        { id: 2, label: '内贸' }
      ],
      rateDisable: false,
      calcSconFormTemp: 0,
      isBussinessDept5: false
    };
  },
  watch: {
    //获取合同金额
    'sconForm.scon_fetotal': function (newval, oldVal) {
      this.sconForm.scon_total = new BigNumber(Number(this.sconForm.scon_fetotal) + Number(this.sconForm.scon_prtotal)).toFixed(4);
    },
    'sconForm.scon_prtotal': function (newVal, oldVal) {
      this.sconForm.scon_total = new BigNumber(Number(this.sconForm.scon_fetotal) + Number(this.sconForm.scon_prtotal)).toFixed(4);
    },
    //获取人民币总金额
    'sconForm.scon_total': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = new BigNumber(this.sconForm.scon_usdrate * this.sconForm.scon_total).toFixed(4);
    },
    'sconForm.scon_usdrate': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = new BigNumber(this.sconForm.scon_usdrate * this.sconForm.scon_total).toFixed(4);
    },
    'sconForm.cust_currency'(newVal, oldVal) {
      if (newVal === 'RMB') {
        this.sconForm.scon_usdrate = 1;
        this.rateDisable = true;
      } else if (newVal === 'USD') {
        this.sconForm.scon_usdrate = 6.3;
        this.rateDisable = false;
      } else {
        this.sconForm.scon_usdrate = null;
      }
    },
    //新增和删除时计算成品价格
    'sconForm.scon_prod_list'() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = new BigNumber(sum).toFixed(4);
    }
  },
  async created() {
    this.getCusts();
    this.getTradeway();
    this.getCustShipway();
    this.getCustPayway();
    this.getCustPaylway();
    this.getCustDport();
    this.getContry();
    this.getCustCurrency();
    this.getSett();
    this.getDept();
    this.getSupp();
    this.getProdRankList();
    this.isBussinessDept5 = [...(await getDept('业务五部', 'ids')), 1].includes(this.$cookies.get('userInfo').dept_id);
  },
  mounted() {
    this.copyScon();
  },
  methods: {
    keepEngAndNumUp,
    //复制过来的数据
    copyScon() {
      let copyData = this.$route.params.syncData;
      if (copyData) {
        //同步过来的数据到主表
        this.sconForm = copyData;
        if (this.sconForm.scon_prod_list.length > 0) {
          this.initPartMtrbListData();
        }
        //回显所属部门和外销经办人
        this.custDeptChange(this.sconForm.cust_dept_id);
      }
    },
    getCusts() {
      let { dept_name, dept_id } = this.$cookies.get('userInfo');
      let currentDeptId = ['业务一部', '业务二部', '业务五部'].includes(dept_name) ? dept_id : null;
      get(custAPI.getAllCustsV3, { dept_id: currentDeptId, page_no: null })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    //默认显示第一条的数据
    initPartMtrbListData() {
      this.$refs.SconProdList.getIndex = 0;
      this.showFlagNumIdCopy = 0;
      this.showFlagNumId = 0;
      if (this.sconForm.scon_prod_list[0].scon_prod_id > 0) {
        this.showFlagNumIdCopy = this.sconForm.scon_prod_list[0].scon_prod_id;
      } else {
        this.showFlagNumId = this.sconForm.scon_prod_list[0].prod_id;
      }
    },
    // 获取供应商
    async getSupp() {
      let [valueListObj, valueListArr] = await getSupp('valueListAndDefault');
      // await (this.collectionContractTableProperties.find(({ label }) => label === '实际供应商').subItem.options = valueListArr);
      // await (this.collectionContractTableProperties.find(({ label }) => label === '指定供应商').subItem.options = valueListArr);
      await this.$refs.SconPartList.changeProperties(valueListObj);
      await this.$refs.sconMtrbList.changeProperties(valueListObj);
      await this.$refs.SconCatnipList.changeProperties(valueListObj);
    },
    // 获取单位
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getSett() {
      get(settAPI.getSettByKey, { sett_key: 'usd_rate' })
        .then(res => {
          if (res.data.code === 0) {
            this.sconForm.scon_usdrate = new BigNumber(res.data.data.form.sett_value).toFixed(6);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    custDeptChange(val) {
      this.getStffUser(val);
      //更改销售所属部门后清空成品表对应外销
      this.sconForm.scon_prod_list.forEach(item => {
        item.scon_stff_id = null;
        item.cust_stff_name = '';
      });
    },
    //获取经办人
    getStffUser(val) {
      get(stffAPI.getAllStffsV1, { dept_id: val })
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取贸易方式
    getTradeway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10016 })
        .then(res => {
          if (res.data.code === 0) {
            this.tradewayList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //装运日期联动更改预计收款日期
    chageShdate(val) {
      //如果装运日期 = null 则预计收款日期 = null
      if (!val) {
        this.sconForm.scon_ppdate = null;
        return;
      }
      //否则计算时间
      let cust_creday = 0;
      if (this.sconForm.scon_shdate) {
        cust_creday = this.sconForm.cust_creday;
      }
      this.sconForm.scon_ppdate = val + cust_creday * 86400000;
    },
    //客户账期联动更改预计收款日期
    changeCreday(val) {
      //如果装运日期 = null 则预计收款日期 = null
      if (!this.sconForm.scon_shdate) {
        this.sconForm.scon_ppdate = null;
        return;
      }
      //否则计算时间
      let time = 0;
      if (val) {
        time = val;
      }
      this.sconForm.scon_ppdate = this.sconForm.scon_shdate + time * 60 * 60 * 24 * 1000;
    },
    // 获取运输方式
    getCustShipway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10017 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShipwayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取价格条款
    getCustPaylway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10014 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaylwayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取付款方式
    getCustPayway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取港
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDportList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取国家
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCountryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //付款方式联动方法
    updatePpdate(val) {
      this.sconForm.cust_creday = Number(this.custPaywayList.find(({ param1 }) => val === param1).param2);
      if (this.sconForm.scon_shdate) {
        this.sconForm.scon_ppdate = this.sconForm.scon_shdate + this.sconForm.cust_creday * 60 * 60 * 24 * 1000;
      }
    },
    // 材料和部件部分
    // 获取公共prod_id
    confirmProdId(val) {
      this.showFlagNumId = val;
    },
    confirmProdIdCopy(val) {
      this.showFlagNumIdCopy = val;
    },
    changeTransmitIndex(val) {
      this.transmitIndex = val;
    },
    addFlagIdChange(val) {
      this.addFlagId = val;
    },

    //提交表单
    submit(formName) {
      let temp = [];
      //判断所有成品下的部件是否都有主产品
      this.sconForm.scon_prod_list.forEach(item => {
        let find = this.sconForm.scon_part_list.filter(x => x.prod_id === item.prod_id);
        if (find.length > 0) {
          let flag = find.some(item => item.prod_mainsup === 1);
          if (!flag) {
            temp.push(item.prod_no);
          }
        }
      });
      if (temp.length > 0) {
        return this.$message.info('成品我司货号：' + temp.toString() + '没有主产品,不能保存');
      }
      //判断客户订单号是否重复
      get(sconAPI.changeCustNoIsRepeat, { scon_cust_no: this.sconForm.scon_cust_no, scon_id: this.sconForm.scon_id })
        .then(res => {
          if (res.data.code === 0) {
            let isRepeat = res.data.data.isFlag;
            this.$confirm(isRepeat ? '客户订单号重复是否继续保存?' : '确认保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async () => {
                let dynamicUTable1 = await this.$refs.SconProdList.$refs.SconProdList.fullValidateMsg();
                let dynamicUTable2 = await this.$refs.SconPartList.$refs.SconPartList.fullValidateMsg();
                let dynamicUTable3 = await this.$refs.sconMtrbList.$refs.SconMtrbList.fullValidateMsg();
                this.$refs[formName].validate((valid, object) => {
                  if (valid && !dynamicUTable1 && !dynamicUTable2 && !dynamicUTable3) {
                    this.saveInfo();
                  } else {
                    let tempStr = '';
                    if (dynamicUTable1) tempStr += `成品子表-${dynamicUTable1}`;
                    if (dynamicUTable2) tempStr += `部件子表-${dynamicUTable2}`;
                    if (dynamicUTable3) tempStr += `材料子表-${dynamicUTable3}`;
                    requiredReminderAndUGrid(object, tempStr);
                  }
                });
              })
              .catch(() => {
                this.$message.info('已取消保存');
              });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 保存
    saveInfo() {
      let sconForm = JSON.parse(JSON.stringify(this.sconForm));
      const staffForm = this.$refs.userData.getData();
      sconForm = Object.assign(sconForm, staffForm);

      sconForm.scon_cndate = this.helper.getNewDate(sconForm.scon_cndate); //合同日期
      sconForm.scon_shdate = this.helper.getNewDate(sconForm.scon_shdate); //装运日期
      sconForm.scon_ppdate = sconForm.scon_ppdate ? this.helper.getNewDate(sconForm.scon_ppdate) : 0; //预计收款日期

      post(sconAPI.addScon, sconForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.$EventBus.$emit('close_current_tags');
            this.jump('/scon_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
            this.resetForm('sconFormRef');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('sconFormRef');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.sconForm.clau_content = null;
      this.sconForm.scon_fees_list = [];
      this.sconForm.scon_mtrb_list = [];
      this.sconForm.scon_part_list = [];
      this.sconForm.scon_catnip_list = [];
      this.sconForm.scon_prod_list = [];
    },
    //选择客户
    clickSelectCust(id, type) {
      let row = cloneDeep(this.custs.find(x => x.cust_id === id));
      this.importCustType = type;
      if (row) {
        this.importCustChange(row);
      }
      // this.dialogCustVisible = true;
    },
    //双击选中 客户
    importCustChange(row) {
      if (this.importCustType === 2) {
        this.getStffUser(row.cust_dept_id);
        this.sconForm.cust_dept_id = row.cust_dept_id;
        this.sconForm.cust_stff_id = row.cust_stff_id;
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
        this.sconForm.cust_dport = row.cust_dport; //目的港
        this.sconForm.cust_payway = row.cust_payway; //付款方式
      } else {
        this.sconForm.cust_dept_id = row.cust_dept_id;
        if (row.cust_stff_id) {
          this.sconForm.cust_stff_id = row.cust_stff_id;
        } else {
          this.sconForm.cust_stff_id = null;
        }
        this.sconForm.cust_abbr = row.cust_abbr; //客户简称
        this.sconForm.cust_id = row.cust_id; //客户id
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
        this.sconForm.cust_tradeway = row.cust_tradeway; //贸易方式
        this.sconForm.cust_shipway = row.cust_shipway; //运输方式
        this.sconForm.cust_paylaw = row.cust_paylaw; //价格条款
        this.sconForm.cust_payway = row.cust_payway; //付款方式
        this.sconForm.cust_creday = row.cust_creday; //客户账期
        this.sconForm.cust_dport = row.cust_dport; //目的港
        this.sconForm.cust_dcountry = row.cust_country_eng; //目的国
        this.sconForm.cptt_aname_en = row.cptt_aname; //公司抬头
        this.$refs.SconClauInfo.changeCpttAnameEn(row.cptt_aname);
        this.sconForm.cptt_aid = row.cptt_aid; //公司抬头id
        this.sconForm.cptt_aname_en = row.cptt_aname; //公司抬头
        this.sconForm.cptt_bname_en = row.cptt_bname_en; //公司抬头 cptt_bname_en
        this.sconForm.cptt_bid = row.cptt_bid; // 收款抬头id
        this.sconForm.cust_shipmark = row.cust_shipmark; //唛头
        this.sconForm.inst_id = row.inst_id;
        this.sconForm.inst_abbr = row.inst_abbr;
        this.sconForm.inst_ename = row.inst_ename;
        this.getStffUser(this.sconForm.cust_dept_id);
        // if (row.cptt_aid !== 0) {
        //   get(cpttAPI.getCpttBankByCpttId, { cptt_id: row.cptt_aid })
        //     .then(res => {
        //       if (res.data.code === 0) {
        //         this.cptt_bank_list = res.data.data.list;
        //         return;
        //       }
        //       this.$message.error(res.data.msg);
        //     })
        //     .catch(res => {
        //       this.$message.error(res.data.msg);
        //     });
        // }
        //机构英文名称
        //银行信息
      }
      this.dialogCustVisible = false;
    },
    //装运日期选择
    shdateChange(evt) {
      if (this.sconForm.cust_creday) {
        this.sconForm.scon_ppdate = evt.getTime() + this.sconForm.cust_creday * 86400000;
      } else {
        this.sconForm.scon_ppdate = evt;
      }
    },
    // 客户账期选择联动影响收款日期
    shdateChange2() {
      let evt = null;
      let type = Object.prototype.toString.call(this.sconForm.scon_shdate);
      if (type != '[object Date]') {
        evt = new Date(this.sconForm.scon_shdate);
      } else {
        evt = this.sconForm.scon_shdate;
      }
      this.shdateChange(evt);
    },
    //条款内容变化
    sconClauChange(row) {
      this.sconForm.clau_content = row.clau_content;
    },
    //机构信息变化
    sconClauChange2(row) {
      this.sconForm.inst_id = row.inst_id;
      this.sconForm.inst_ename = row.inst_ename;
      this.sconForm.inst_abbr = row.inst_abbr;
    },
    //费用金额 变化
    feetPriceChange(val) {
      this.sconForm.scon_fetotal = new BigNumber(val).toFixed(4);
    },
    sconProdPriceChange() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = new BigNumber(sum).toFixed(4);
    },
    changeCurrency(type) {
      this.sconForm.cust_currency = type;
      this.$refs.SconProdList.changePriceName(type);
    },
    //检查客户订单号是否重复
    changeCustNoIsRepeat(val) {
      if (val) {
        get(sconAPI.changeCustNoIsRepeat, { scon_cust_no: val, scon_id: this.sconForm.scon_id })
          .then(res => {
            if (res.data.code === 0) {
              let flag = res.data.data.isFlag;
              if (flag) {
                this.$message.error('客户订单号重复！');
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    changeCustStff(val) {
      this.sconForm.scon_prod_list.forEach(item => {
        item.scon_stff_id = val;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
